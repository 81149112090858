<template>
<div class="admin-order-wrapper mb-2">
    <div class="container">
        <div class="row adminOrder mb-0">
            <div class="col-md-12 adminOrder__header" @click="toggle(item.id)" :class="{ opened: opened.includes(item.id) }">
                <div class="adminOrder__header-number w-25">
                    <b>Заказ №{{item.unique_code}}</b>
                    <p v-if="this.$route.path!='/admin/preparation/history'&item.type_of_eating!=null"> {{item.type_of_eating.title}} ({{item.type_of_eating.time_start}}-{{item.type_of_eating.time_end}})</p>
               <div class="adminOrder__header-day" v-if="this.$route.path=='/admin/preparation/history' || this.$route.path=='/admin/preparation/assembly'">{{$moment(item.date_day).format("DD.MM.YYYY")}}</div> 
                </div>
                <div class="adminOrder__header-type w-25" v-if="this.$route.path=='/admin/preparation/history'&item.type_of_eating!=null">
                    {{item.type_of_eating.title}} ({{item.type_of_eating.time_start}}-{{item.type_of_eating.time_end}})
                </div> 
                <div class="w-25">
                  <star-rating v-if="this.$route.path=='/admin/preparation/history'" :read-only='true' :rating="item.stars" :star-size="20" text-class="d-none" ></star-rating>
                </div>
                <div class="adminOrder__header-time" :class="this.$route.path!='/admin/preparation/history'?'w-100':''">
                    Время выдачи: {{item.selected_time}}
                </div>
                <div class="adminOrder__header-show">
                    <span class="toggle"></span>
                </div>
            </div>
            <div class="col-md-12" v-if="opened.includes(item.id)">
                <div class="row gx-3">
                    <div class="col-md-3" v-for="item in item.products" :key="item.id">
                        <div class="adminOrderItem">
                            <div v-if="$route.path!='/admin/preparation/history'">
                            <div class="adminOrderItem__number" v-if="item.every_day_product!=null && item.every_day_product.cell!=null" :style="{backgroundColor: item.group.color}">
                                {{item.every_day_product.cell.name}}
                            </div>
                            </div>
                            <div v-else>
                                 <div class="adminOrderItem__number" v-if="item.files.length!=0">
        <img :src="`https://elimleli2.itfactory.site${item.files[0].thumb_path}`" /> 
        <!-- <img :src="require('../assets/cartitem.jpg')" />  -->
    </div>
    <div class="cart-item__noimg" v-else>
    </div>
                            </div>
                            <div class="adminOrderItem__desc">
                                <b>{{item.name}}</b>
                                <span>{{item.quantity}} {{decQua(item.quantity)}}</span>
                            </div>
                        </div>
                    </div>
                      <div class="col-md-3">
                            <div class="adminOrderItem align-items-center">

                                <div class="adminOrderItem__desc adminOrderItem__desc--center">
                                    <b>{{item.type_of_way.title}}</b>

                                </div>
                            </div>
                        </div>
                </div>
                <div class="adminOrder__btn">
                    <b v-if="$route.path == '/admin/preparation/history'">{{item.status.name}}</b>
                    <button class="btn-oval me-2" v-if="$route.path == '/admin/preparation/assembly'" @click="collected(nextStatus,item.id)">Собрано</button>
                    <button class="btn-oval me-2" v-if="$route.path == '/admin/preparation/assembled'" @click="collected(nextStatus,item.id)">Доставлено</button>
                    <button class="btn-oval me-2" v-if="$route.path == '/admin/preparation/history'&&item.status.name=='Отменен'" @click="collected(nextStatus,item.id)">Заполнить реестр</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import StarRating from 'vue-star-rating'
//import FoodItemOperator from '@/components/FoodItemOperator.vue'
// import AdminMenuHorizont from '@/components/AdminMenuHorizont.vue'
export default {
    props: {
        item: Object,
        nextStatus: Number,
        currentStatus: Array
    },
    data() {
        return {
            opened: [],
            rows: []
        };
    },
    components: {
 StarRating
        //         Navbar,
        //  AdminMenuHorizont
        //  FoodItemOperator
    },
//     mounted() {
//         this.opened.length =0
//         const id = this.$store.state.admin.adminOrders[0].id
//         alert(this.$store.state.admin.adminOrders[0].id)
//         if(this.item.id == id){
// this.opened.push(this.item.id)
//         }

//     },
    async mounted() {
     const id = this.$store.state.admin.adminOrders[0].id
        this.opened.push(id)
    },
    watch:{
'$store.state.admin.adminOrders': {
            //immediate: true,
            async handler() {
              const id = this.$store.state.admin.adminOrders[0].id
        this.opened.push(id)
            }
        },
    },
    methods: {
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        },
        async collected(status_id, order_id) {

            await this.$store.dispatch("actionChangeStatus", {'status_id': status_id,'order_id': order_id});
            // await this.$store.dispatch("actionGetListOrder", this.currentStatus);
            const data = {
                status_id: this.currentStatus,
                date: this.$store.state.admin.dayOfWeek
            }
            this.$store.commit("loader", true);
            await this.$store.dispatch("actionGetAdminOrders", data);
            this.$store.commit("loader", false);

        },
               decQua(count) {
            if (count == 1) {
                return 'порция';
            } else if (count > 1 && count <= 4) {
                return 'порции';
            } else if (count >= 5) {
                return 'порций';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.admin-order-wrapper {
    background: #F5F5F5;
    border-radius: 9px;
    overflow: hidden;
}

.adminOrder {
    position: relative;
    background: #F5F5F5;
    padding-top: 25px;
    padding-bottom: 3px;

    &__header {
        display: flex;
        justify-content: space-between;
        //align-items: center;
        font-family: Montserrat;
        cursor: pointer;

        // margin-bottom: 18px;
        max-width: calc(100% - 34px);
        margin-left: auto;
        margin-right: auto;
    }

    &__header-number {
        font-size: 16px;
        margin-bottom: 17px;
       width: 200px;
        p {
            padding: 0;
            margin: 0;
        }
    }

    &__header-time {
        font-size: 18px;

        font-weight: 700;
        text-align: right;
        width: 33%;
        padding-right: 30px;

font-weight: 600;
font-size: 16px;
line-height: 20px;
/* identical to box height */



color: #FF7728;
    }

    .orange {
        color: $orange;
    }

    &__btn {
        margin-top: 20px;
        padding-bottom: 29px;
    }
}

.showFullOrder {
    font-weight: 500;
    font-size: 16px;
    font-family: Montserrat;
    cursor: pointer;
}

.g-3,
.gx-3 {
    --bs-gutter-x: .6rem;
}

.toggle {
    border: solid #777777;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
}

.opened {
    border-bottom: 1px solid #DEDEDE;
    margin-bottom: 14px;
}

.opened .toggle {
    transform: rotate(225deg);
}

.adminOrder__btn {
    display: flex;
    align-items: center;

    b {
        margin-right: 16px;
        font-family: Montserrat;
        margin-left: 16px;
        font-weight: 600;
        font-size: 18px;
    }
}

.adminOrderItem {
    display: flex;
    font-family: Montserrat;
    background: #fff;
    //opacity: .5;
    padding: 15px;
    width: 300px;
    height: 102px;
    border-radius: 9px;
    max-width: 315px;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;

    &__number {
        width: 88px;
        height: 72px;
        background: #000;
        color: #fff;
        font-weight: bold;
        font-size: 24px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        &--orange {
            background: $orange;
        }

        &--blue {
            background: #0041E8;
        }
    }

    &__desc {
        padding-left: 13px;

        b {
            display: block;

        }

        span {
            display: block;
        }

        &--center {
            width: 100%;
            text-align: center;
        }
    }
}
    .cart-item__noimg{
     height: 72px;
    width: 88px !important;

    margin-right: 25px;
      background: url(../assets/nophoto.png) 50% 50% no-repeat #EBEBEB;
background-size:contain;
      border-radius: 12px;
    
    }
    .adminOrderItem__number{
        img{
            max-width: 88px;
            height: 72px;
        }
    }
    .adminOrder__header-day{
        text-align: left;
    }
</style>
